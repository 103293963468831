import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import AddTestModal from "../AddTestModal";
// =========
import {Link} from 'react-router-dom';
import Container from "@mui/material/Container";
import PhoneMenu from "../Common/PhoneMenu";
import SidebarMenu from "../Common/SidebarMenu";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import AddCompanyIcon from "../Common/assets/imgs/add-device.svg";
import MapBlueIcon from "../Common/assets/imgs/map.svg";

const AddCompany = ({ clientId, clientName, handleLogout,clientNameHeader }) => {
  const [open, setOpen] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [companyDevicesData, setCompanyDevicesData] = useState([]);
  const [testFiles, setTestFiles] = useState([]);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [formData, setFormData] = useState({
    companyName: "",
    website: "",
    owner: "",
  });
  const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);
  const [deviceID, setDeviceID] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [deviceScheduledTestDate, setDeviceScheduledTestDate] = useState("");
  const [selectedDeviceID, setSelectedDeviceID] = useState("");

  const [companyNameDupeExists, setCompanyNameDupeExists] = useState(false);

  const handleOpenSubmitModal = () => setOpenSubmitModal(true);
  const handleCloseSubmitModal = () => setOpenSubmitModal(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${formData.companyName.replace(
        / /g,
        "%20"
      )}/checkcompanyduplicates`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.response === "Company with this name already exists.") {
          setCompanyNameDupeExists(true);
          console.log("setting state", companyNameDupeExists);
        } else {
          setCompanyNameDupeExists(false);
          console.log("Not a dupe");
        }
      });
  }, [formData.companyName]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/postcompany`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then(() => {
        setAlertContent("Company created successfully!");
        setAlert(true);
        handleOpenSubmitModal();
      })
      .then((result) => {
        console.log(result);
      });
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid item xs={12} md={10} className={toggleMenus === true ? "tooggleActive mainContent" : "mainContent"}>
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    ADD NEW COMPANY
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                >
                </Grid>

                <Grid item xs={12} className="tabSect">
                  <Box
                    align="left"
                    sx={{
                      mt: 1,
                      "& .MuiTextField-root": { m: 1, width: "30ch" },
                    }}
                    autoComplete="off"
                  >
                    <h3>Company Details</h3>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Grid sx={{ mt: 2 }}>
                        <Grid>
                          <TextField
                            //error={formData.companyName === ''}
                            error={
                              formData.companyName === "" ||
                              companyNameDupeExists
                            }
                            id="outlined-select-currency"
                            style={{ width: 550 }}
                             //label="Company Name"
                            required
                            helperText={
                              companyNameDupeExists
                                ? "Warning- company with this name already exists"
                                : "Company name"
                            }
                            value={formData.companyName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                companyName: e.target.value,
                              })
                            }
                          ></TextField>
                        </Grid>
                        <Grid>
                          <TextField
                            id="outlined-select-currency"
                            style={{ width: 550 }}
                             //label="Website"
                            helperText="Company website"
                            value={formData.website}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                website: e.target.value,
                              })
                            }
                          ></TextField>
                        </Grid>
                        <TextField
                          id="outlined-select-currency"
                          style={{ width: 300 }}
                           //label="Owner"
                          helperText="Company owner"
                          value={formData.owner}
                          onChange={(e) =>
                            setFormData({ ...formData, owner: e.target.value })
                          }
                        ></TextField>
                      </Grid>

                      <Grid>
                        <Box sx={{ display: "inline-flex" }}>
                          <AddTestModal
                            submitTestFileAndData={handleSubmit}
                            openStatus={openSubmitModal}
                            handleModalClose={handleCloseSubmitModal}
                            alertContentText={alertContent}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="success"
                            sx={{
                              ml: 1,
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              width: 225,
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            size="large"
                            href="/"
                            variant="contained"
                            sx={{
                              mr: 6,
                              mt: 4,
                              borderRadius: 8,
                              borderRadius: 8,
                              width: 225,
                            }}
                            color="error"
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default AddCompany;
