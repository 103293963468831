import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Logo from "./assets/imgs/footerLogo.svg";
import { Link } from "react-router-dom";
import { PopupButton } from "react-calendly";

export default function Footer() {
  return (
    <Grid container spacing={2} className="footer" pl={40}>
      <Grid item xs={4} className="logosect">
        <img src={Logo} alt="logo" />{" "}
        <span>FLO TRACE © All rights reserved 2024.</span>
      </Grid>
      <Grid item xs={6} className="footerInfo">
        <h3>Have Support Questions/Feedback?</h3>
        <div className="footerLinks">
          {/* <Link
            to="https://calendly.com/lynnshort?hide_event_type_details=1"
            target="_blank"
          >
            {"Schedule a Consultation"}
          </Link> */}
          <PopupButton
            url="https://calendly.com/lynnshort?hide_event_type_details=1"
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
            text="Schedule a Consultation"
            className="candlyBtn"
          />
          <Link to="https://www.flo-trace.com/contact/" target="_blank">
            {"Submit a Form"}
          </Link>
          <Link to="tel:3612128243">{"Call 361.212.8243"}</Link>
        </div>
      </Grid>
    </Grid>
  );
}
