import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Link } from "react-router-dom";
import DeviceBlueIcon from "./Common/assets/imgs/device.svg";
import TechnicianBlueIcon from "./Common/assets/imgs/technician.svg";
import ContactBlueIcon from "./Common/assets/imgs/contact.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import AddCompanyIcon from "./Common/assets/imgs/add-company.svg";
import AddContactIcon from "./Common/assets/imgs/add-contact.svg";
import AddDeviceIcon from "./Common/assets/imgs/add-device.svg";
import AddTechnicianIcon from "./Common/assets/imgs/add-technician.svg";
import AddTestIcon from "./Common/assets/imgs/add-test.svg";

//href="/"
export const MainListItems = (slug) => {
  return (
    <React.Fragment>
      <ListItemButton component={Link} to="/" className={slug === '/' ? 'activeItem' : ''} >
        {/* <Link to="/" /> */}
        <ListItemIcon>
          <img
            src={DeviceBlueIcon}
            className="Icon_blue"
            alt="deviceBlue_ICON"
          />
        </ListItemIcon>
        <ListItemText primary="Devices" />
      </ListItemButton>
      {/* <ListItemButton>
      <ListItemIcon>
        <GasMeterIcon />
      </ListItemIcon>
      <ListItemText primary="Devices" />
    </ListItemButton> */}
      {/* <ListItemButton>
      <ListItemIcon>
        <EmailIcon /> 
      </ListItemIcon>
      <ListItemText primary="Letters" />
    </ListItemButton> */}
      <ListItemButton component={Link} to="/technicians" className={slug === '/technicians' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img
            src={TechnicianBlueIcon}
            className="Icon_blue"
            alt="TechnicianBlueIcon"
          />
        </ListItemIcon>
        <ListItemText primary="Technicians" />
      </ListItemButton>
      <ListItemButton component={Link} to="/contacts" className={slug === '/contacts' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img
            src={ContactBlueIcon}
            className="Icon_blue"
            alt="ContactBlueIcon"
          />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItemButton>
      <ListItemButton component={Link} to="/map" className={slug === '/map' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img src={MapBlueIcon} className="Icon_blue" alt="MapBlueIcon" />
        </ListItemIcon>
        <ListItemText primary="Map" />
      </ListItemButton>
    </React.Fragment>
  );
};

export const SecondaryListItems = (slug, isToggleMenu) => {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        {!isToggleMenu? "ADD NEW": ""}
      </ListSubheader>

      <ListItemButton component={Link} to="/addcompany" className={slug === '/addcompany' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img
            src={AddCompanyIcon}
            className="Icon_black"
            alt="AddCompanyIcon"
          />
        </ListItemIcon>
        <ListItemText component="a" primary="Company" />
      </ListItemButton>

      <ListItemButton component={Link} to="/addcontact" className={slug === '/addcontact' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img
            src={AddContactIcon}
            className="Icon_black"
            alt="AddContactIcon"
          />
        </ListItemIcon>
        <ListItemText component="a" primary="Contact" />
      </ListItemButton>

      <ListItemButton component={Link} to="/adddevice" className={slug === '/adddevice' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img src={AddDeviceIcon} className="Icon_black" alt="AddDeviceIcon" />
        </ListItemIcon>
        <ListItemText component="a" primary="Device" />
      </ListItemButton>

      <ListItemButton component={Link} to="/addtechnician" className={slug === '/addtechnician' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img
            src={AddTechnicianIcon}
            className="Icon_black"
            alt="AddTechnicianIcon"
          />
        </ListItemIcon>
        <ListItemText component="a" primary="Technician" />
      </ListItemButton>

      <ListItemButton component={Link} to="/addtest" className={slug === '/addtest' ? 'activeItem' : ''}>
        <ListItemIcon>
          <img src={AddTestIcon} className="Icon_black" alt="AddTestIcon" />
        </ListItemIcon>
        <ListItemText component="a" primary="Test" />
      </ListItemButton>

      {/* <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Overdue" />
    </ListItemButton> */}
    </React.Fragment>
  );
};
