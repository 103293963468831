import flotraceLogo from "./Common/assets/imgs/FLO-logo.png";
import Box from '@mui/material/Box';

export default function FloTraceLogoHeader() {

  return (
  <Box className="topLogo"
  component="img"
  sx={{
    height: 150,
    width: 150,
    mt: 0,
    ml:0,
    borderRadius:4,
    border: '1px solid grey',
    // boxShadow: "-15px -5px 15px -1px #CECECE"
  }}
  alt="Flo-Trace Logo"
  src={flotraceLogo}
/>
  )
  
  
  
  // <img src={flotraceLogo} alt="Flo-Trace logo"  />
}
