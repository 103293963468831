import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "./Copyright";
import CategoryCard from "./CategoryCard";
import DeviceTable from "./DeviceTable";
import FloTraceLogoWhite from "./FloTraceLogoWhite";
import GenerateLettersModal from "./GenerateLettersModal";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import Header from "./Common/Header";
import Footer from "./Common/Footer";
import SidebarMenu from "./Common/SidebarMenu";
import AlertImg from "./Common/assets/imgs/alert.svg";
import AddCompanyIcon from "./Common/assets/imgs/add-device.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import PhoneMenu from "./Common/PhoneMenu";
import moment from "moment/moment";

const defaultTheme = createTheme();

export default function Dashboard({ handleLogout, clientId, clientName, clientNameHeader }) {
  const [open, setOpen] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [currentlyFilteringBy, setCurrentlyFilteringBy] = useState("");
  const [numDevices90Days, setNumDevices90Days] = useState();
  const [numDevices30Days, setNumDevices30Days] = useState();
  const [numDevicesOverdue, setNumDevicesOverdue] = useState();
  const [numDevicesViolation, setNumDevicesViolation] = useState();
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [selectedRowsContactData, setSelectedRowsContactData] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [activeItem, setActiveItem] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  useEffect(() => {
    const removeDuplicates = (arr) => {
      return [...new Set(arr)];
    };

  

    // useEffect(() => {
    //   const today = new Date()
    //   const todayPlus30 = today.setDate(today.getDate() + 30)
    //   const todayPlus90 = today.setDate(today.getDate() + 90)
    //   console.log("OVERDUE LENGTH", selectedTableRows.filter(record => record.NewSchTestDate!==null && record.IsMandatory === "Yes" && new Date(record.NewSchTestDate) < today).length)
    // }, [])

    const companyIDs = removeDuplicates(
      selectedTableRows.map((row) => row.CompanyID)
    ).toString();

    // GET COMPANY CONTACT INFO BY ID
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${companyIDs}/getcompanycontactinfobyid`
    )
      .then((response) => response.json())
      .then((data) => {
        setSelectedRowsContactData(data.data);
      });
  }, [selectedTableRows]);

  const handleSelectedTableRows = (tableRows) => {
    setSelectedTableRows(tableRows);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // console.log("Number devices found overdue", numDevicesOverdue);
  }, [numDevicesOverdue, toggleMenus, isActive]);

  const handleSetNumDevices = (deviceDataArray) => {
    const today = new Date();
    const todayPlus30 = today.setDate(today.getDate() + 30);
    const todayPlus90 = today.setDate(today.getDate() + 90);

    setNumDevicesOverdue(
      deviceDataArray.filter(
        (record) =>
          record.NewSchTestDate !== null &&
          record.IsMandatory === "Yes" &&
          new Date(record.NewSchTestDate) < new Date()
      ).length
    );

    setNumDevicesViolation(
      deviceDataArray.filter(
        (record) =>
          record.NewSchTestDate !== null &&
          record.IsMandatory === "Yes" &&
          moment(new Date(record.NewSchTestDate)).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")&&
          moment(new Date(record.NewSchTestDate)).format("YYYY-MM-DD") <= moment(new Date().setDate(today.getDate() + 10)).format("YYYY-MM-DD")
      ).length
    );

    //setNumDevicesOverdue(deviceDataArray.filter(record => record.NewSchTestDate!==null && record.IsMandatory === "Yes" && new Date(record.NewSchTestDate) < today).length)
    setNumDevices30Days(
      deviceDataArray.filter(
        (record) =>
          record.NewSchTestDate !== null &&
          record.IsMandatory === "Yes" &&
          new Date(record.NewSchTestDate) >= new Date() &&
          new Date(record.NewSchTestDate) <=
            new Date().setDate(today.getDate() + 30)
      ).length
    );
    setNumDevices90Days(
      deviceDataArray.filter(
        (record) =>
          record.NewSchTestDate !== null &&
          record.IsMandatory === "Yes" &&
          moment(new Date(record.NewSchTestDate)).format("YYYY-MM-DD") >=
            moment(new Date()).format("YYYY-MM-DD") &&
          moment(new Date(record.NewSchTestDate)).format("YYYY-MM-DD") <=
            moment(new Date().setDate(today.getDate() + 90)).format(
              "YYYY-MM-DD"
            )
        // new Date().setDate(today.getDate() + 90)
      ).length
    );
  };

  const handleSeeDevicesNinetyDays = () => {
    // if (isFiltered === false) {

    (function () {
      const today = new Date();
      let todayPlus90 = today.setDate(today.getDate() + 90);
      todayPlus90 = moment(todayPlus90).format("YYYY-MM-DD");
      const currentDate = moment(new Date()).format("YYYY-MM-DD");

      setFilterModel({
        items: [
          {
            field: "NewSchTestDate",
            operator: "onOrBefore",
            value: todayPlus90,
          },
          {
            field: "NewSchTestDate",
            operator: "onOrAfter",
            value: currentDate,
          },
          {
            field: "IsMandatory",
            operator: "equals",
            value: "Yes",
          },
        ],
      });
    })();
    setActiveItem("Due in 90 days");
    setIsActive(true);
    // } else {
    //   setFilterModel({ items: [] });
    //   setActiveItem("");
    //   setIsActive(false);
    // }
    // setIsFiltered(!isFiltered);
  };

  const handleSeeDevicesThirtyDays = () => {
    (function () {
      const today = new Date();
      let todayPlus30 = today.setDate(today.getDate() + 30);
      todayPlus30 = moment(todayPlus30).format("YYYY-MM-DD");
      const currentDate = moment(new Date()).format("YYYY-MM-DD");
      setFilterModel({
        items: [
          {
            field: "NewSchTestDate",
            operator: "onOrBefore",
            value: todayPlus30,
          },
          {
            field: "NewSchTestDate",
            operator: "onOrAfter",
            value: currentDate,
          },
          {
            field: "IsMandatory",
            operator: "equals",
            value: "Yes",
          },
        ],
      });
    })();
    setActiveItem("Due in 30 days");
    setIsActive(true);
    // }
    // else {
    //   setFilterModel({ items: [] });
    //   setActiveItem("");
    //   setIsActive(false);
    // }
    // setIsFiltered(!isFiltered);
  };

  const handleSeeDevicesOverdue = () => {
    // if (isFiltered === false) {
    (function () {
      const currentDate = moment(new Date()).format("YYYY-MM-DD");
      setFilterModel({
        items: [
          {
            field: "NewSchTestDate",
            operator: "onOrBefore",
            value: currentDate,
          },
          {
            field: "IsMandatory",
            operator: "equals",
            value: "Yes",
          },
        ],
      });
    })();
    setActiveItem("Overdue");
    setIsActive(true);
    // } else {
    //   setFilterModel({ items: [] });
    //   setActiveItem("");
    //   setIsActive(false);
    // }
    // setIsFiltered(!isFiltered);
  };

  const handleSeeVoilations = () => {
    // if (isFiltered === false) {
    (function () {
      const today = new Date();
      const currentDate = moment(new Date()).format("YYYY-MM-DD");
      let todayPlus10 = today.setDate(today.getDate() + 10);
      todayPlus10 = moment(todayPlus10).format("YYYY-MM-DD");
      setFilterModel({
        items: [
          {
            field: "NewSchTestDate",
            operator: "onOrBefore",
            value: currentDate,
          },
          {
            field: "NewSchTestDate",
            operator: "onOrBefore",
            value: todayPlus10,
          },
          {
            field: "IsMandatory",
            operator: "equals",
            value: "Yes",
          },
        ],
      });
    })();
    setActiveItem("Voilation");
    setIsActive(true);
    // } else {
    //   setFilterModel({ items: [] });
    //   setActiveItem("");
    //   setIsActive(false);
    // }
    // setIsFiltered(!isFiltered);
  };

  const handleSeeDeselectAll = () => {
    setFilterModel({ items: [] });
    setActiveItem("");
    setIsActive(true);
  };

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    // '@media all': {
    //   minHeight: 128,
    // },
  }));

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu />
            <SidebarMenu toggleMenu={setToggleMenus}/>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                {/* Due Within 90 Days */}
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  className={
                    activeItem === "Due in 90 days" ? "activeBtn box" : "box"
                  }
                >
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "row",
                    }}
                    className="inner"
                  >
                    <CategoryCard
                      onClickSeeDevices={handleSeeDevicesNinetyDays}
                      title={"Due in"}
                      titleNum={"90 days"}
                      valNumDevices={numDevices90Days}
                      updateTableTitle={currentlyFilteringBy}
                    />
                  </Paper>
                </Grid>
                {/* Due Within 30 Days */}
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  className={
                    activeItem === "Due in 30 days" ? "activeBtn box" : "box"
                  }
                >
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "row",
                    }}
                    className="inner"
                  >
                    <CategoryCard
                      onClickSeeDevices={handleSeeDevicesThirtyDays}
                      title={"Due in"}
                      titleNum={"30 days"}
                      valNumDevices={numDevices30Days}
                    />
                  </Paper>
                </Grid>
                {/* Overdue */}
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={3}
                  className={activeItem === "Overdue" ? "activeBtn box" : "box"}
                >
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "row",
                      background: `url(${AlertImg})`,
                    }}
                    className="inner danger"
                  >
                    <CategoryCard
                      onClickSeeDevices={handleSeeDevicesOverdue}
                      titleNum={"Overdue"}
                      valNumDevices={numDevicesOverdue}
                      valueColor={"error.main"}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={3} lg={3} className={activeItem === "Voilation" ? "activeBtn box" : "box"}>
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "row",
                      background: `url(${AlertImg})`,
                    }}
                    className="inner danger"
                  >
                    <CategoryCard
                      onClickSeeDevices={handleSeeVoilations}
                      titleNum={"VIOLATION"}
                      valNumDevices={numDevicesViolation}
                      valueColor={"error.main"}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={4} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    DEVICES {activeItem !== "" && <span>({activeItem})</span>}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  className="beforetable text_center"
                >
                  <Typography
                    className="seeAllDevices"
                    alignItems={"center"}
                    align={"center"}
                    variant="h4"
                    gutterBottom
                    onClick={handleSeeDeselectAll}
                  >
                    Clear All Filters
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                >
                  <Link to="/map" title="View Map">
                    <img
                      src={MapBlueIcon}
                      width={80}
                      className="Icon_blue"
                      alt="deviceBlue_ICON"
                    />
                  </Link>
                  <Link to="/adddevice" title="Add Device">
                    <img
                      src={AddCompanyIcon}
                      width={75}
                      className="Icon_black"
                      alt="AddCompanyIcon"
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} className="tabSect">
                  <DeviceTable
                    clientId={clientId}
                    clientName={clientName}
                    currentFilterModel={filterModel}
                    setFilterModelFunction={setFilterModel}
                    onSaveNumDevices={handleSetNumDevices}
                    onSelectRows={handleSelectedTableRows}
                    currentlySelectedRows={selectedTableRows}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{ textAlign: "center", paddingTop: 0 }}
                  >
                    <GenerateLettersModal
                      clientId={clientId}
                      clientName={clientName}
                      selectedRowsData={selectedTableRows}
                      selectedContactsData={selectedRowsContactData}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid sx={{ backgroundColor: "#2596be" }}> */}
              {/* <FloTraceLogo style={{mt: 2, height:"200px", width:"200px"}}/> */}
              {/* <FloTraceLogoWhite
                  style={{ mt: 2, height: "200px", width: "200px" }}
                /> */}

              {/* <Copyright sx={{ pt: 4 }} /> */}
              {/* </Grid> */}
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
