import React, { useState, useEffect } from "react";
import Title from "./Title";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import moment from "moment";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomToolbar from "./CustomToolbar";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Container from "@mui/material/Container";
import PhoneMenu from "./Common/PhoneMenu";
import SidebarMenu from "./Common/SidebarMenu";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import AddTechnicianIcon from "./Common/assets/imgs/add-technician.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default function TechniciansTable({
  handleLogout,
  onSelectRows,
  currentFilterModel,
  clientId,
  clientName,
  clientNameHeader,
}) {
  const [isUpdate, setIsUpdate] = useState(false);
  const [techniciansData, setTechniciansData] = useState([]);
  const [open, setOpen] = useState(true);
  const [selectedIDs, setSelectedIDs] = useState(1);
  const [retireTechnicianModalStatus, setRetireTechnicianModalStatus] =
    useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);

  const handleOpenModal = () => setRetireTechnicianModalStatus(true);
  const handleCloseModal = () => setRetireTechnicianModalStatus(false);
  const [toggleMenus, setToggleMenus] = useState(false);
  const [confimModal, setConfrimModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleRetireTechnician = (params) => {
    setSelectedIDs(params.id);

    const options = {
      // title: "Title",
      message: "Are you sure you want to retire technician?",
      buttons: [
        {
          label: "Yes",
          className: "confimBtn",
          onClick: () => {
            setConfrimModal(false);
            retiredTechnicianByID(params.id);
          },
        },
        {
          label: "No",
          className: "cancelBtn",
          onClick: () => {
            setConfrimModal(false);
            handleEditCloseModal();
          },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypress: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    };

    setConfrimModal(true);

    if (!confimModal) {
      confirmAlert(options);
    }
  };

  const handleEditCloseModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModal(false);
    }
  };
  const UpdatedMessageOptions = {
    title: "This technician has been retired and removed.",
    overlayClassName: "overlay-custom-class-name successMessage",
    buttons: [
      {
        label: "Close",
        className: "cancelBtn",
        onClick: () => {
          setConfrimModal(false);
          return false;
        },
      },
    ],
  };
  //   // title: "Title",
  //   message: "Are you sure you want to retire technician?",
  //   buttons: [
  //     {
  //       label: "Yes",
  //       className: "confimBtn",
  //       onClick: () => {
  //         setConfrimModal(false);
  //         retiredTechnicianByID();
  //       },
  //     },
  //     {
  //       label: "No",
  //       className: "cancelBtn",
  //       onClick: () => {
  //         setConfrimModal(false);
  //         handleEditCloseModal();
  //       },
  //     },
  //   ],
  //   closeOnEscape: true,
  //   closeOnClickOutside: false,
  //   keyCodeForClose: [8, 32],
  //   willUnmount: () => {},
  //   afterClose: () => {},
  //   onClickOutside: () => {},
  //   onKeypress: () => {},
  //   onKeypressEscape: () => {},
  //   overlayClassName: "overlay-custom-class-name",
  // };

  const retiredTechnicianByID = (techID) => {
    fetch(`https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${techID}/retiretechnician`)
      .then((response) => response.json())
      .then((response) => {
        confirmAlert(UpdatedMessageOptions);
        setIsUpdate(!isUpdate);
      })
      .then((result) => {
        console.log(result);
      });
  };

  const techniciansColumns = [
    { field: "TechID", headerName: "Technician ID", editable: true, width: 75 },
    { field: "TechName", headerName: "Tech Name", editable: true, width: 150 },
    { field: "FirmName", headerName: "Firm Name", editable: true, width: 300 },
    {
      field: "CertTestNumber",
      headerName: "Certification Test Number",
      editable: true,
      type: "number",
      width: 150,
      headerAlign: "center",
      align: "center",
      textAlign: "center",
      renderHeader: (params) => (
        <strong>
          {"Certification"}
          <br />
          {"Test Number"}
        </strong>
      ),
    },
    {
      field: "ExpiredDate",
      headerName: "Expired Date",
      editable: true,
      width: 120,
      valueFormatter: (params) => {
        moment(params?.value).format("MM/DD/YYYY");
      },
      type: "date",
    },
    {
      field: "MobilePhone",
      headerName: "Mobile Phone",
      editable: true,
      width: 150,
    },
    {
      field: "WorkPhone1",
      headerName: "Work Phone 1",
      editable: true,
      width: 165,
    },
    {
      field: "WorkPhone2",
      headerName: "Work Phone 2",
      editable: true,
      width: 125,
    },
    { field: "City", headerName: "City", editable: true, width: 125 },
    { field: "State", headerName: "State", editable: true, width: 80 },
    {
      field: "Postal Code",
      headerName: "Zipcode",
      editable: true,
      width: 80,
    },
    {
      field: "Retire Technician",
      width: 150,
      headerAlign: "center",
      align: "center",
      headerName: "Retire Technician",
      renderCell: (params) => (
        <RemoveCircleIcon
          onClick={() => handleRetireTechnician(params)}
          color="error"
        />
      ),
    },
  ];

  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/technicians`
    )
      .then((response) => response.json())
      .then((data) => {
        setTechniciansData(data.data);
      });
  }, [isUpdate]);

  useEffect(() => {
    console.log("WILL BE DELETED", selectedIDs);
  }, [selectedIDs]);

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            className={
              toggleMenus === true ? "tooggleActive mainContent" : "mainContent"
            }
          >
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    TECHNICIANS
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable"
                >
                  <Link to="/map" title="View Map">
                    <img
                      src={MapBlueIcon}
                      width={80}
                      className="Icon_blue"
                      alt="deviceBlue_ICON"
                    />
                  </Link>
                  <Link to="/addtechnician" title="Add Technicians">
                    <img
                      src={AddTechnicianIcon}
                      width={75}
                      className="Icon_black"
                      alt="AddCompanyIcon"
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} className="tabSect">
                  <DataGridPremium
                    className="dashboardTable"
                    getRowId={(row) => row.TechID}
                    pagination
                    checkboxSelection={false}
                    disableMultipleRowSelection={true}
                    onRowSelectionModelChange={(ids) => {
                      setSelectedIDs(ids[0]);
                    }}
                    initialState={{
                      ...techniciansData.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                      columns: {
                        columnVisibilityModel: {
                          TechID: false,
                          MobilePhone: true,
                          ExpiredDate: false,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                    editMode="row"
                    filterModel={currentFilterModel}
                    rows={techniciansData}
                    loading={techniciansData.length === 0}
                    columns={techniciansColumns}
                    components={{ Toolbar: CustomToolbar }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
