// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("CenturyGothic-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("CenturyGothic-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("CenturyGothic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("CenturyGothic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("CenturyGothic-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("CenturyGothic-Italic.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Century Gothic';
    src: local('Century Gothic Bold'), local('CenturyGothic-Bold'),
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: local('Century Gothic'), local('CenturyGothic'),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: local('Century Gothic Italic'), local('CenturyGothic-Italic'),
        url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

`, "",{"version":3,"sources":["webpack://./src/assets/fonts/stylesheet.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B;;8DAEiD;IACjD,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B;;8DAE4C;IAC5C,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B;;8DAEmD;IACnD,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Century Gothic';\n    src: local('Century Gothic Bold'), local('CenturyGothic-Bold'),\n        url('CenturyGothic-Bold.woff2') format('woff2'),\n        url('CenturyGothic-Bold.woff') format('woff');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Century Gothic';\n    src: local('Century Gothic'), local('CenturyGothic'),\n        url('CenturyGothic.woff2') format('woff2'),\n        url('CenturyGothic.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Century Gothic';\n    src: local('Century Gothic Italic'), local('CenturyGothic-Italic'),\n        url('CenturyGothic-Italic.woff2') format('woff2'),\n        url('CenturyGothic-Italic.woff') format('woff');\n    font-weight: normal;\n    font-style: italic;\n    font-display: swap;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
