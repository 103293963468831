import React, { useState, useEffect, useContext } from "react";
import Title from "./Title";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Link} from 'react-router-dom';
import Typography from "@mui/material/Typography";
import CustomToolbar from "./CustomToolbar";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Container from "@mui/material/Container";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import PhoneMenu from "./Common/PhoneMenu";
import SidebarMenu from "./Common/SidebarMenu";
import AddContactIcon from "./Common/assets/imgs/add-contact.svg";
import MapBlueIcon from "./Common/assets/imgs/map.svg";

export default function ContactsTable({
  handleLogout,
  onSelectRows,
  currentFilterModel,
  clientId,
  clientName,
  clientNameHeader
}) {
  const [contactsData, setContactsData] = useState([]);
  const [retireContactModalStatus, setRetireContactModalStatus] =
    useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alert, setAlert] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState(1);
  const [open, setOpen] = useState(true);

  const handleOpenModal = () => setRetireContactModalStatus(true);
  const handleCloseModal = () => setRetireContactModalStatus(false);
  const [toggleMenus, setToggleMenus] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // const handleModalClose= () => {
  //   setRetireContactModalStatus(false);
  // }

  const handleRetireContact = (e) => {
    e.preventDefault();

    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/${selectedIDs}/retirecontact`
    )
      .then((response) => response.json())
      .then((response) => {
        setAlertContent("Contact retired successfully.");
        setAlert(true);
        setOpenSubmitModal(true);
      })
      .then((result) => {
        console.log(result);
      });
  };

  useEffect(() => {
    console.log("WILL BE DELETED", selectedIDs);
  }, [selectedIDs]);

  const contactsColumns = [
    { field: "ContactID", headerName: "Contact ID", editable: true, width: 75 },
    { field: "LastName", headerName: "Last Name", editable: true, width: 210 },
    {
      field: "FirstName",
      headerName: "First Name",
      editable: true,
      width: 110,
    },
    { field: "EmailAddress", headerName: "Email", editable: true, width: 125 },
    { field: "JobTitle", headerName: "Job Title", editable: true, width: 175 },
    {
      field: "BusinessPhone",
      headerName: "Business Phone",
      editable: true,
      width: 175,
    },
    {
      field: "HomePhone",
      headerName: "Home Phone",
      editable: true,
      width: 125,
    },
    {
      field: "MobilePhone",
      headerName: "Mobile Phone",
      editable: true,
      width: 125,
    },
    { field: "FaxNumber", headerName: "Fax", editable: true, width: 125 },
    {
      field: "StreetAddress",
      headerName: "Street Address",
      editable: true,
      width: 125,
    },
    { field: "City", headerName: "City", editable: true, width: 105 },
    { field: "State", headerName: "State", editable: true, width: 80 },
    {
      field: "PostalCode",
      headerName: "Postal Code",
      editable: true,
      width: 125,
    },
    {
      field: "Retire",
      width: 180,
      headerName: "Retire Contact",
      renderCell: (params) => (
        <RemoveCircleIcon onClick={handleOpenModal} color="error" />
      ),
    },
  ];

  useEffect(() => {
    fetch(
      `https://flo-trace-api-prod-ugyxg.ondigitalocean.app/${clientId}/contacts`
    )
      .then((response) => response.json())
      .then((data) => {
        setContactsData(data.data);
      });
  }, []);

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/* Header Section */}
            <Header
              clientId={clientId}
              clientName={clientName}
              handleLogout={handleLogout}
              istoggleMenu={toggleMenus}
              clientNameHeader={clientNameHeader}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            {/* Footer Section */}
            <PhoneMenu slug="/technicians" />
            <SidebarMenu toggleMenu={setToggleMenus} />
          </Grid>
          <Grid item xs={12} md={10} className={toggleMenus === true ? "tooggleActive mainContent" : "mainContent"}>
            <Container maxWidth="xlg" sx={{ mt: 8, mb: 4 }}>
              <Grid container spacing={10} className="topDueSect">
                <Grid item xs={12} sm={12} md={6} className="beforetable">
                  <Typography
                    className="SelDevice"
                    align="left"
                    variant="h4"
                    gutterBottom
                  >
                    CONTACTS
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  sx={{ textAlign: "right" }}
                  className="beforetable">
                    <Link to="/map" title="View Map">
                    <img
                      src={MapBlueIcon}
                      width={80}
                      className="Icon_blue"
                      alt="deviceBlue_ICON"
                    />
                  </Link>
                  <Link to="/addcontact" title="Add Contact">
                    <img
                      src={AddContactIcon}
                      width={75}
                      className="Icon_black"
                      alt="AddCompanyIcon"
                    />
                  </Link>
                  </Grid>

                <Grid item xs={12} className="tabSect">
                  <DataGridPremium
                    className="dashboardTable"
                    getRowId={(row) => row.ContactID}
                    pagination
                    checkboxSelection={false}
                    disableMultipleRowSelection={true}
                    onRowSelectionModelChange={(ids) => {
                      setSelectedIDs(ids[0]);

                      //const selectedIDs = new Set(ids);
                      //console.log(selectedIDs)
                      // const selectedRows = contactsData.filter((row) =>
                      //   selectedIDs.has(row.ContactID)
                      // );
                    }}
                    //   onSelectRows(selectedRows);
                    //   console.log("Selected Rows", selectedRows)
                    // }}

                    // getDetailPanelHeight={getDetailPanelHeight}
                    // getDetailPanelContent={getDetailPanelContent}
                    // detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    // onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    initialState={{
                      ...contactsData.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                      columns: {
                        columnVisibilityModel: {
                          ContactID: false,
                          HomePhone: false,
                          FaxNumber: false,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                    editMode="row"
                    filterModel={currentFilterModel}
                    rows={contactsData}
                    loading={contactsData.length === 0}
                    columns={contactsColumns}
                    //onFilterModelChange={(newFilterModel) => props.setFilterModelFunction(newFilterModel)}
                    components={{ Toolbar: CustomToolbar }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
