import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Title from './Title';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';


export default function CategoryCard(props) {

  return (
    <React.Fragment >
      <div className='desc'>
        <Title>{props.title} <span>{props.titleNum}</span></Title>
      <Typography variant="h4" className='devices'>
        {props.valNumDevices}
      </Typography>
      </div>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
      {'as of ' + new Date().toJSON().slice(0, 10)}
      </Typography> */}
      <div className='actSect'>
      {/* <img src={DownArrow} className='btnIcon' alt='DownArrow' /> */}
        <Button 
            variant="outlined"
            color="primary"
            onClick={props.onClickSeeDevices}
            >
          <KeyboardDoubleArrowDownIcon/>
        </Button>
      </div>
     </React.Fragment>
  );
}