import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppBar from "../AppBar";
import FloTraceLogoHeader from "../FloTraceLogoHeader";
import BackGroundImg from './assets/imgs/header-bgrnd.png';

export default function Header({ handleLogout, clientId, clientName, istoggleMenu, clientNameHeader }) {

  const[clientDisplayName, setClientDisplayName]= useState('');

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    // '@media all': {
    //   minHeight: 128,
    // },
  }));
  useEffect(()=>{

    const token = localStorage.getItem('token');
    axios({
      method: "GET",
      url:"https://flo-trace-api-prod-ugyxg.ondigitalocean.app/get_client_display_name",
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then((response) => {
      const res =response.data;
      setClientDisplayName(res.client_display_name);
      localStorage.setItem('client_display_name', res.client_display_name);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
},[]);
console.log({istoggleMenu});

  const sectionStyle = {
    backgroundImage:`url(${BackGroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    boxShadow: "none",
  };

  const currentDate = new Date();
  const current_date = currentDate.getDate();
  // Get the current month (0-indexed, so add 1)
  const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });

  // Get the current year
  const currentYear = currentDate.getFullYear();

  return (
    <AppBar className="MainHeader" style={sectionStyle}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} className={istoggleMenu ? "togggledSidebar logoSection" : "logoSection"}>
            <FloTraceLogoHeader />
          </Grid>
          <Grid className="descSect" item xs={12} md={8}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <h1>Flo-Trace<sup>®</sup></h1>
                        <p>by LSPS Solutions</p>
                        <div className="welcome">
                            <h4>Welcome {clientNameHeader}</h4>
                        </div>
                    </Grid>
                    <Grid className="centerSect" item xs={12} md={7}>
                        <h2>{localStorage.getItem('client_display_name') !==''?  localStorage.getItem('client_display_name'): clientDisplayName }</h2>
                    </Grid>
                </Grid>
            </Box>
            {/* <h3></h3> */}
          </Grid>
          <Grid item xs={12} md={2} className="logOutSect">
            <Button
              sx={{ color: "white" }}
              onClick={handleLogout}
              variant="contained"
            >Logout</Button>
            <p>{currentMonth} {current_date}, {currentYear}</p>
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
}
